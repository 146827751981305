:root {
  --card-padding: 24px;
  --card-height: 340px;
  --card-skeleton: linear-gradient(lightgrey var(--card-height), transparent 0);
  --avatar-size: 32px;
  --avatar-position: var(--card-padding) var(--card-padding);
  --avatar-skeleton: radial-gradient(
    circle 16px at center,
    white 99%,
    transparent 0
  );
  --title-height: 32px;
  --title-width: 60%;
  --title-position: var(--card-padding) 180px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);
  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(
    white var(--desc-line-height),
    transparent 0
  );
  --desc-line-1-width: calc(100% - var(--card-padding) * 2);
  --desc-line-1-position: var(--card-padding) 242px;
  --desc-line-2-width: 70%;
  --desc-line-2-position: var(--card-padding) 265px;
  --footer-height: 40px;
  --footer-position: 0 calc(var(--card-height) - var(--footer-height));
  --footer-skeleton: linear-gradient(white var(--footer-height), transparent 0);
  --blur-width: 70%;
  --blur-size: var(--blur-width) calc(var(--card-height) - var(--footer-height));
}

/*
   * You can also use media queries to adjust the skeleton
   */
@media screen and (min-width: 64em) {
  :root {
    --title-width: 40%;
    --card-padding: 32px;
  }
}
/*
   * Card Skeleton for Loading
   */
.card {
  width: 100%;
  margin: 0 auto;
  height: var(--card-height);
}
.card:empty::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, rgba(211, 211, 211, 0)),
      color-stop(50%, rgba(211, 211, 211, 0.8)),
      to(rgba(211, 211, 211, 0))
    ),
    var(--title-skeleton), var(--desc-line-skeleton), var(--desc-line-skeleton),
    var(--avatar-skeleton), var(--footer-skeleton), var(--card-skeleton);
  background-image: linear-gradient(
      90deg,
      rgba(211, 211, 211, 0) 0,
      rgba(211, 211, 211, 0.8) 50%,
      rgba(211, 211, 211, 0) 100%
    ),
    var(--title-skeleton), var(--desc-line-skeleton), var(--desc-line-skeleton),
    var(--avatar-skeleton), var(--footer-skeleton), var(--card-skeleton);
  background-size: var(--blur-size), var(--title-width) var(--title-height),
    var(--desc-line-1-width) var(--desc-line-height),
    var(--desc-line-2-width) var(--desc-line-height),
    var(--avatar-size) var(--avatar-size), 100% var(--footer-height), 100% 100%;
  background-position: -150% 0, var(--title-position),
    var(--desc-line-1-position), var(--desc-line-2-position),
    var(--avatar-position), var(--footer-position), 0 0;
  background-repeat: no-repeat;
  -webkit-animation: loading 1.5s infinite;
  animation: loading 1.5s infinite;
}

@-webkit-keyframes loading {
  to {
    background-position: 350% 0, var(--title-position),
      var(--desc-line-1-position), var(--desc-line-2-position),
      var(--avatar-position), var(--footer-position), 0 0;
  }
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--title-position),
      var(--desc-line-1-position), var(--desc-line-2-position),
      var(--avatar-position), var(--footer-position), 0 0;
  }
}

