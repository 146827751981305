
.blogcard {
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.09);
  border-radius: 0.375rem;
  overflow: hidden;
}

.cardlink {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
}
.cardlink:hover .posttitle {
  transition: color 0.3s ease;
  color: #e04f62;
}
.cardlink:hover .postimage {
  transition: opacity 0.3s ease;
  opacity: 0.9;
}

.postimage {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  object-fit: cover;
}

.articledetails {
  padding: 1.5rem;
}

.postcategory {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
}

.posttitle {
  transition: color 0.3s ease;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #121212;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.postauthor {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

@media (max-width: 40rem) {
  #container {
    width: 18rem;
    height: 27.25rem;
  }

  .blogcard {
    flex-wrap: wrap;
  }
}
@supports (display: grid) {

  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }

  .postimage {
    height: 100%;
  }

  .blogcard {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }

  @media (max-width: 40rem) {
    .blogcard {
      grid-template-columns: auto;
      grid-template-rows: 12rem 1fr;
    }
  }
}
